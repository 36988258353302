import { NgClass } from '@angular/common';
import {
  Component,
  ElementRef,
  Renderer2,
  computed,
  effect,
  inject,
  input,
  viewChild,
} from '@angular/core';
import { IDeviceInfo } from '@desquare/interfaces';
import {
  NgbProgressbarModule,
  NgbTooltipModule,
} from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { FileSizePipe } from '../pipe/file-size/file-size.pipe';

@Component({
  standalone: true,
  imports: [
    NgClass,
    TranslateModule,
    NgbTooltipModule,
    NgbProgressbarModule,
    FileSizePipe,
  ],
  selector: 'app-device-memory',
  template: `
    @defer(on viewport){ @if(isNumber(deviceInfo().memory?.free)){
    <div #badge style="min-width: 3rem" class="px-1 badge">
      <span>
        {{ usedInPercent() + '%' }}
      </span>
    </div>
    <span class="ms-2">
      {{ deviceInfo().memory?.used | fileSize }} /
      {{ deviceInfo().memory?.total | fileSize }}</span
    >
    }} @placeholder {
    <div
      class="spinner-border spinner-border-sm"
      role="status"
      aria-hidden="true"
    >
      <span class="text-secondary visually-hidden">
        {{ 'LOADING' | translate }}...
      </span>
    </div>
    }
  `,
  styles: [
    `
      :host {
        display: flex;
        width: 100%;
        align-items: center;
        flex-wrap: nowrap;
      }
    `,
  ],
})
export class DeviceMemoryComponent {
  renderer = inject(Renderer2);
  deviceInfo = input.required<IDeviceInfo>();
  protected badge = viewChild<ElementRef>('badge');

  usedInPercent = computed(() =>
    Math.round(
      (this.deviceInfo().memory?.used / this.deviceInfo().memory?.total) * 100
    )
  );

  unusedInPercent = computed(() => 100 - this.usedInPercent());
  renderEffect = effect(() => {
    if (this.usedInPercent() && this.badge()?.nativeElement)
      this.renderer.setStyle(
        this.badge()?.nativeElement,
        'background',
        `linear-gradient(90deg, ${this.badgeColor(this.usedInPercent())} ${
          this.usedInPercent() - 1
        }%,  rgb(108 117 125) ${this.usedInPercent()}%)`
      );
  });

  isNumber(val: any): boolean {
    return typeof val === 'number';
  }
  badgeColor(percent: number) {
    if (percent < 60) {
      return this.hexToRgb('#178863');
    } else if (percent < 80) {
      return this.hexToRgb('#bf9a10');
    } else {
      return this.hexToRgb('#f1556c');
    }
  }
  hexToRgb(hex: string) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? `
    rgb(${parseInt(result[1], 16)} ${parseInt(result[2], 16)} ${parseInt(
          result[3],
          16
        )})
    `
      : null;
  }
}
